<template>
    <div>
        <div class="form-row">
            <div class="form-col w-1/2">
                <label for="community_floorplan_name">internal name</label>
                <text-input id="community_floorplan_name" v-model="floorplan.community_floorplan_name" />
                <span v-if="hasError('floorplan.community_floorplan_name')" class="form-error">{{ showError('floorplan.community_floorplan_name') }}</span>

                <label for="common_name" class="mt-4">public name</label>
                <text-input id="common_name" v-model="floorplan.common_name" />
                <span v-if="hasError('floorplan.common_name')" class="form-error">{{ showError('floorplan.common_name') }}</span>

                <label for="unit_type" class="mt-4">unit type</label>
                <text-input id="unit_type" v-model="floorplan.unit_type" />

                <span v-if="hasError('floorplan.unit_type')" class="form-error">{{ showError('floorplan.unit_type') }}</span>

                <label for="square_feet" class="mt-4">square footage</label>
                <text-input type="number" min="0" id="square_feet" v-model="floorplan.square_feet" />
                <span v-if="hasError('floorplan.square_feet')" class="form-error">Must not be less than 0.</span>

                <div class="mt-8">
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory('cms')"
                        :max-upload-size=20
                        :max-nb-of-files=10
                        :cropper-options="{aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false}"
                        v-model="floorplan.gallery.images"
                    >
                        <template v-slot:hint="{ canAddFiles, maxNbOfFiles, maxUploadSize }">
                            <span class="form-hint" v-if="canAddFiles">
                                Select at least 1 image (up to {{ maxNbOfFiles }}).
                                The max upload file size is {{ maxUploadSize }}MB.
                            </span>
                        </template>
                    </file-manager>
                    <span v-if="hasError('floorplan.gallery.images')" class="form-error">{{ showError('floorplan.gallery.images') }}</span>
                </div>
            </div>
            <div class="form-col w-1/2">
                <label for="starting_price">starting price</label>
                <AmountInput 
                    v-if="!this.source"
                    v-model="floorplan.starting_price" 
                    id="starting_price"
                    prefix="$" 
                    amountClass="form-input"
                    
                />
                <span v-if="hasError('floorplan.starting_price')" class="form-error">{{ showError('floorplan.starting_price') }}</span>

                <label for="bedrooms" class="mt-4">bedrooms</label>
                <text-input type="number" min="0" id="bedrooms" v-model="floorplan.bedrooms" />
                <span v-if="hasError('floorplan.bedrooms')" class="form-error">Must not be less than 1.</span>

                <label for="bathrooms" class="mt-4">bathrooms</label>
                <text-input type="number" min="0" step=".5" id="bathrooms" v-model="floorplan.bathrooms" />
                <span v-if="hasError('floorplan.bathrooms')" class="form-error">Must not be less than 1.</span>

                <label for="available_units" class="mt-4">available</label>
                <text-input type="number" min="0" id="available_units" v-model="floorplan.available_units" />
                <span v-if="hasError('floorplan.bathrooms')" class="form-error">Must not be less than 0.</span>

                <label for="virtual_tour_url" class="mt-4">virtual tour url</label>
                <text-input id="virtual_tour_url" v-model="floorplan.virtual_tour_url" />
                <span v-if="hasError('floorplan.virtual_tour_url')" class="form-error">{{ showError('floorplan.virtual_tour_url') }}</span>

                <label for="description" class="mt-4">description</label>
                <text-input id="description" v-model="floorplan.description" multiline />
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import FileManager from "@/components/ui/filemanager/FileManager";
    import TextInput from '@/components/ui/TextInput';
    import AmountInput from '@/components/ui/AmountInput';

    export default {
        mixins: [ ModalNavigation ],
        components: { ModalFooter, FileManager, TextInput, AmountInput },
        data: () => {
            return {
                website: {},
                floorplan: {
                    name: '',
                    common_name: '',
                    unit_type: null,
                    square_feet: 0,
                    bedrooms: 1,
                    bathrooms: 1,
                    available_units: 0,
                    starting_price: 1,
                    virtual_tour_url: null,
                    gallery: {
                        name: '',
                        images: []
                    }
                },
                isProcessing: false,
            }
        },
        computed: {
            ...mapGetters({
                getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadFloorplansInStore: 'floorplans/loadFloorplans',
                notifySuccessBack: 'alerts/notifySuccessBack',
                notifyError: 'alerts/notifyError',
            }),
            save() {
                if(this.validator.passes()) {
                    const payload = this.floorplan;

                    payload.name = this.floorplan.community_floorplan_name;
                    payload.customers_id = this.website.customers_id;
                    payload.communities_id = this.website.communities_id;
                    payload.websites_id = this.website.id;

                    payload.gallery.images.forEach( (value, key) => {
                        payload.gallery.images[key] = { url: this.getThumbnailUrlByFileId(value) };
                    } )

                    this.isProcessing = true;
                    this.$cmsDataProvider.create('createOrUpdateFloorplan', { websiteId: this.website.id, data: payload })
                    .then(() => {
                        this.$cmsDataProvider.get('floorplans', {websiteId: this.website.id}).then((response) => {
                            this.loadFloorplansInStore(response);
                        })
                        this.isProcessing = false;
                        this.notifySuccessBack('The floorplan was added successfully');
                    }).catch(() => {
                        this.isProcessing = false;
                        this.notifyError('There was an error saving the information');
                    });
                }
            }           
        },
        validations: {
            'floorplan.community_floorplan_name' : 'required',
            'floorplan.common_name': 'required',
            'floorplan.unit_type': 'required',
            'floorplan.gallery.images': 'required',
            'floorplan.starting_price' : 'required | numeric | min:1',
            'floorplan.virtual_tour_url': 'when_present | url',
            'floorplan.square_feet': 'when_present | integer | min:0',
            'floorplan.bedrooms': 'when_present | integer | min:0',
            'floorplan.bathrooms': 'when_present | numeric | min:0',
            'floorplan.available_units': 'when_present | integer| min:0',
        },
        created() {
            this.initValidator();
        },
        async mounted() {
            this.website = this.getWebsiteInfo
        },
        watch: {
            floorplan: {
                deep:true,
                handler() {
                    if (this.floorplan.available_units < 0) this.floorplan.available_units = 0
                    if (this.floorplan.bedrooms < 0) this.floorplan.bedrooms = 0
                    if (this.floorplan.bathrooms < 0) this.floorplan.bathrooms = 0
                    if (this.floorplan.square_feet < 0) this.floorplan.square_feet = 0
                }
            }
        }
    }
</script>

<style scoped>
.floorplan-photo .remove-photo {
    display: none;
}

.floorplan-photo:hover .remove-photo {
    display: block;
}

.form-input {
    @apply pl-10;
}

</style>
